<template>
  <div style="font-size:14px">
   <div class="welcome">
      <div class="container">
        <div class="welcome-agileinfo">
          <div class="col-xs-4 col-sm-4 welcome-w3lsgrids">
            <div class="w3lwelcome-img effect-ruby" id="feature-btn-1">
              <img
                class="img-responsive"
                src="./../../../../Public/utils/new-index/images/feature-1.jpg"
                alt=" "
              />
              <div class="agile-caption">
                <h3>国内唯一基于智能算法专利的专业定位产品</h3>
                <p>查看详情</p>
              </div>
            </div>
          </div>
          <div class="col-xs-4 col-sm-4 welcome-w3lsgrids">
            <div class="w3lwelcome-img effect-ruby" id="feature-btn-2">
              <img
                class="img-responsive"
                src="./../../../../Public/utils/new-index/images/feature-2.jpg"
                alt=" "
              />
              <div class="agile-caption">
                <h3>七个专业定位测评报告，全方位考虑周全</h3>
                <p>查看详情</p>
              </div>
            </div>
          </div>
          <div class="col-xs-4 col-sm-4 welcome-w3lsgrids">
            <div class="w3lwelcome-img effect-ruby" id="feature-btn-3">
              <img
                class="img-responsive"
                src="./../../../../Public/utils/new-index/images/feature-3.png"
                alt=" "
              />
              <div class="agile-caption">
                <h3>九个中学学科能力测评报告，了解学科薄弱点</h3>
                <p>查看详情</p>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="welcome-agileinfo" style="margin-top: 2em;">
          <div class="col-xs-4 col-sm-4 welcome-w3lsgrids">
            <div class="w3lwelcome-img effect-ruby" id="feature-btn-4">
              <img
                class="img-responsive"
                src="./../../../../Public/utils/new-index/images/feature-4.jpg"
                alt=" "
              />
              <div class="agile-caption">
                <h3>专业匹配精准率超 80%，快速准确定位</h3>
                <p>查看详情</p>
              </div>
            </div>
          </div>
          <div class="col-xs-4 col-sm-4 welcome-w3lsgrids">
            <div class="w3lwelcome-img effect-ruby" id="feature-btn-5">
              <img
                class="img-responsive"
                src="./../../../../Public/utils/new-index/images/feature-5.jpg"
                alt=" "
              />
              <div class="agile-caption">
                <h3>92个高校大类专业全景匹配，703个小类全匹配</h3>
                <p>查看详情</p>
              </div>
            </div>
          </div>
          <div class="col-xs-4 col-sm-4 welcome-w3lsgrids">
            <div class="w3lwelcome-img effect-ruby" id="feature-btn-6">
              <img
                class="img-responsive"
                src="./../../../../Public/utils/new-index/images/feature-6.jpg"
                alt=" "
              />
              <div class="agile-caption">
                <h3>双一流专业信息与高校专业详细解读</h3>
                <p>查看详情</p>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <!-- //welcome-->
    <!-- features-->
     <div class="features" id="feature-page-1">
      <div class="container">
        <h2 class="agileits-title">国内唯一基于智能算法专利的专业定位产品</h2>
        <p class="pagile-text" style="margin-top: 2em;">
          专业匹配定位系统基于全国唯一的人工智能专业定位国家发明专利，依据独有的专业匹配算法驱动
        </p>
        <div class="features-row">
          <div class="col-md-5 features-right">
            <img
              class="img-responsive"
              src="./../../../../Public/utils/new-index/images/tip-1.jpg"
              alt=""
            />
          </div>

          <div class="col-md-7 features-left">
            <div class="features-grid">
              <div class="col-xs-3 features-grid-left">
                <i class="fa fa-heart-o" aria-hidden="true"></i>
              </div>
              <div class="col-xs-9 features-grid-right">
                <h4>基于人工智能专利算法</h4>
                <p>
                  核心产品“专业智能定位系统”基于全国唯一的人工智能专业定位国家发明专利（专利号：201810642977.1），在国内处于同类领先地位。
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <div class="features" id="feature-page-2">
      <div class="container">
        <h2 class="agileits-title">七大专业定位测评报告</h2>
        <p class="pagile-text" style="margin-top: 2em;">
          专业匹配定位系统基于全国唯一的人工智能专业定位国家发明专利，依据独有的专业匹配算法驱动
        </p>
        <div class="features-row">
          <div class="col-md-5 features-right">
            <img
              class="img-responsive"
              src="./../../../../Public/utils/new-index/images/tip-2.png"
              alt=""
            />
          </div>

          <div class="col-md-7 features-left">
            <div class="features-grid">
              <div class="col-xs-3 features-grid-left">
                <i class="fa fa-heart-o" aria-hidden="true"></i>
              </div>
              <div class="col-xs-9 features-grid-right">
                <h4>职业倾向报告</h4>
                <p>反映测试者将来的职业倾向，同时对相关的倾向进行分析解读</p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="features-grid features-grid-mdl">
              <div class="col-xs-3 features-grid-left">
                <i class="fa fa-cogs" aria-hidden="true"></i>
              </div>
              <div class="col-xs-9 features-grid-right">
                <h4>人格倾向报告</h4>
                <p>反映测试者的内在人格特征，并对测试者提出相关的改进建议</p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="features-grid">
              <div class="col-xs-3 features-grid-left">
                <i class="fa fa-line-chart" aria-hidden="true"></i>
              </div>
              <div class="col-xs-9 features-grid-right">
                <h4>创新素质指数报告</h4>
                <p>
                  反映测试者目前的创新倾向与创新能力，并对如何提升自我创新进行建议
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="features-grid features-grid-mdl">
              <div class="col-xs-3 features-grid-left">
                <i class="fa fa-check-square-o" aria-hidden="true"></i>
              </div>
              <div class="col-xs-9 features-grid-right">
                <h4>最匹配与最不匹配的大类专业报告</h4>
                <p>
                  给出与测试者特质与能力最匹配的15个高校大类专业推荐；同时给出与测试者最不匹配的5个高校大类专业
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="features-grid">
              <div class="col-xs-3 features-grid-left">
                <i class="fa fa-bar-chart" aria-hidden="true"></i>
              </div>
              <div class="col-xs-9 features-grid-right">
                <h4>最匹配专业详细报告</h4>
                <p>
                  对测试者最匹配的15个高校大类专业进行详细解读，包括专业相关课程，开设专业的高校介绍以及专业就业出口情况描述
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="features-grid features-grid-mdl">
              <div class="col-xs-3 features-grid-left">
                <i class="fa fa-recycle" aria-hidden="true"></i>
              </div>
              <div class="col-xs-9 features-grid-right">
                <h4>最适合大类专业内小类专业排序报告</h4>
                <p>对92大类专业内的703个小专业进行与测试者匹配的精准排序</p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="features-grid">
              <div class="col-xs-3 features-grid-left">
                <i class="fa fa-industry" aria-hidden="true"></i>
              </div>
              <div class="col-xs-9 features-grid-right">
                <h4>高校大类专业全匹配报告</h4>
                <p>
                  通过智能匹配对测试者与高校92个大类进行全匹配排序，给出每个专业与测试者匹配精准分数
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <div class="features" id="feature-page-3">
      <div class="container">
        <h2 class="agileits-title">九个中学学科能力测评报告</h2>
        <p class="pagile-text" style="margin-top: 2em;">
          专业匹配定位系统基于全国唯一的人工智能专业定位国家发明专利，依据独有的专业匹配算法驱动
        </p>
        <div class="features-row">
          <div class="col-md-5 features-right">
            <img
              class="img-responsive"
              src="./../../../../Public/utils/new-index/images/tip-3.png"
              alt=""
            />
          </div>

          <div class="col-md-7 features-left">
            <div class="features-grid">
              <div class="col-xs-3 features-grid-left">
                <i class="fa fa-heart-o" aria-hidden="true"></i>
              </div>
              <div class="col-xs-9 features-grid-right">
                <h4>应对新高考选科目</h4>
                <p>
                  系统中的“中学学科能力测评”对9门学科（语数外+6门选考科目）进行科学能力测评，帮助学生在初中阶段或者高中一、二年级就可助考生提前锁定高考选科科目，学习更有目标。
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="features-grid">
              <div class="col-xs-3 features-grid-left">
                <i class="fa fa-heart-o" aria-hidden="true"></i>
              </div>
              <div class="col-xs-9 features-grid-right">
                <h4>应对新高考选科目</h4>
                <p>
                  对9门学科的错题类型与分布进行统计，让学生找到自己的学习薄弱点，方便精准发力。
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <div class="features" id="feature-page-4">
      <div class="container">
        <h2 class="agileits-title">专业匹配精准率超80%</h2>
        <p class="pagile-text" style="margin-top: 2em;">
          专业匹配定位系统基于全国唯一的人工智能专业定位国家发明专利，依据独有的专业匹配算法驱动
        </p>
        <div class="features-row">
          <div class="col-md-5 features-right">
            <img
              class="img-responsive"
              src="./../../../../Public/utils/new-index/images/tip-4.png"
              alt=""
            />
          </div>

          <div class="col-md-7 features-left">
            <div class="features-grid">
              <div class="col-xs-3 features-grid-left">
                <i class="fa fa-heart-o" aria-hidden="true"></i>
              </div>
              <div class="col-xs-9 features-grid-right">
                <h4>专业匹配精准</h4>
                <p>
                  依据独有的专业匹配算法驱动，并结合20000份测试样本分析与常模构建，帮助考试在40分钟内对自己适合的专业进行快速准确定位。用户调查反映受测者的专业匹配率准确率超过80%。
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <div class="features" id="feature-page-5">
      <div class="container">
        <h2 class="agileits-title">
          92个高校大类专业全景匹配，703个小类全匹配
        </h2>
        <p class="pagile-text" style="margin-top: 2em;">
          专业匹配定位系统基于全国唯一的人工智能专业定位国家发明专利，依据独有的专业匹配算法驱动
        </p>
        <div class="features-row">
          <div class="col-md-5 features-right">
            <img
              class="img-responsive"
              src="./../../../../Public/utils/new-index/images/tip-5.jpg"
              alt=""
            />
          </div>

          <div class="col-md-7 features-left">
            <div class="features-grid">
              <div class="col-xs-3 features-grid-left">
                <i class="fa fa-heart-o" aria-hidden="true"></i>
              </div>
              <div class="col-xs-9 features-grid-right">
                <h4>大类和小类专业配精准排序</h4>
                <p>
                  测评系统通过智能匹配对测试者与高校92个大类进行全匹配排序，给出每个专业与测试者匹配精准分数。同时可对92大类专业内的703个小专业进行与测试者匹配的精准排序。
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <div class="features" id="feature-page-6">
      <div class="container">
        <h2 class="agileits-title">双一流专业信息与高校专业详细解读</h2>
        <p class="pagile-text" style="margin-top: 2em;">
          专业匹配定位系统基于全国唯一的人工智能专业定位国家发明专利，依据独有的专业匹配算法驱动
        </p>
        <div class="features-row">
          <div class="col-md-5 features-right">
            <img
              class="img-responsive"
              src="./../../../../Public/utils/new-index/images/tip-6.png"
              alt=""
            />
          </div>

          <div class="col-md-7 features-left">
            <div class="features-grid">
              <div class="col-xs-3 features-grid-left">
                <i class="fa fa-heart-o" aria-hidden="true"></i>
              </div>
              <div class="col-xs-9 features-grid-right">
                <h4>双一流专业信息</h4>
                <p>
                  加入对匹配专业的双一流信息，同时加入匹配专业的智能搜索和高校排名，全面把握双一流信息，对高校排名胸有成竹。
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="features-grid features-grid-mdl">
              <div class="col-xs-3 features-grid-left">
                <i class="fa fa-cogs" aria-hidden="true"></i>
              </div>
              <div class="col-xs-9 features-grid-right">
                <h4>高校专业详细解读</h4>
                <p>
                  对测试者最匹配的高校大类专业进行详细解读，包括专业相关课程，开设专业的高校介绍以及专业就业出口情况描述。
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <!-- //features-->
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: '',
  data: function () {
    return {}
  },
  mounted: function () {
    function showPage (page) {
      for (var i = 1; i <= 6; i++) {
        $('#feature-page-' + i).hide()
      }
      $('#feature-page-' + page).show()
    }
    showPage(1)
    for (var i = 1; i <= 6; i++) {
      $('#feature-btn-' + i).click(function () {
        var idx = Number(
          $(this)
            .attr('id')
            .replace('feature-btn-', '')
        )
        showPage(idx)
      })
    }
  },
  methods: {}
}
</script>

<style lang="less">
.agile-caption {
  font-size: 1rem;
}
</style>
